import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const useGetTeamsPageData = () => {
  return useStaticQuery(graphql`
    {
      allPrismicAddToSlackTeamsPage(filter: { tags: { in: ["TeamsV2"] } }) {
        nodes {
          data {
            title {
              text
            }
            subtitle {
              text
            }
            linkimage {
              url
              alt
              gatsbyImageData
            }
            link {
              url
            }
            integrationimage {
              url
              alt
              gatsbyImageData
            }
            body {
              ... on PrismicAddToSlackTeamsPageDataBodyGeneralCard {
                id
                primary {
                  description
                  title
                }
              }
            }
            linktext
          }
        }
      }
    }
  `)?.allPrismicAddToSlackTeamsPage?.nodes?.[0]?.data
}

export default useGetTeamsPageData
