import { Layout, SEO } from "components"
import React from "react"
import "./index.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import useGetTeamsPageData from "hooks/useGetTeamsPageData"
import ProductFeatureSection from "components/ProductFeaturesSection/ProductFeaturesSection"

const AddToTeams = () => {
  const data = useGetTeamsPageData()
  const {
    title = {},
    subtitle = {},
    linkimage = {},
    link = {},
    integrationimage = {},
    body = [],
    linktext = "",
  } = data
  return (
    <Layout>
      <SEO {...body?.[0]?.primary} />
      <>
        <div className="TeambleIntegrations">
          <GatsbyImage
            alt={integrationimage?.alt}
            image={integrationimage?.gatsbyImageData}
          />
          <div className="header-title">{title?.text}</div>
          <div className="subtitle">{subtitle?.text}</div>
          <a target="_blank" href={link?.url}>
            <div className="integrationInfo">
              {linkimage.gatsbyImageData && (
                <GatsbyImage
                  image={linkimage?.gatsbyImageData}
                  alt={linkimage?.alt ?? "Review item icons"}
                  objectFit="contain"
                />
              )}
              <div className="name">
                <div className="desktop-text">{linktext}</div>
              </div>
            </div>
          </a>
        </div>
        <ProductFeatureSection />
      </>
    </Layout>
  )
}

export default AddToTeams
